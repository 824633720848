import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { drivingRestaurantEfficiency } from "../../../data/landing/gated-v2";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["firstname", "email", "phone", "company", "get_demo"];

const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Retail point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv1 = () => {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Gated Assets | Driving Restaurant Efficiency—and Profits—With POS Reporting"
        description="Download your free guide and see how you can get better insights about your business with cloud-based POS reporting. Cut costs, boost sales, and maximize your staff."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row
        className="gate-heading-v1"
        gutter={[32, 32]}
        align="middle"
        id="restuarant-retail-planing"
      >
        <Col lg={12}>
          <div id="oaugd">
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              Driving Restaurant Efficiency—and Profits—With POS Reporting
            </Heading>
            <p className="gated-intro-para cta-light">
              It’s harder than ever to maintain a healthy profit margin, but
              with real-time data from your point-of-sale, you can get the
              insights you need to cut costs and increase revenue. See how.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              btnText="Submit"
              formTitle="Download your free playbook"
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formItems={data}
              formDataToSend={formDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free download today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/driving-restaurant-efficiency-and-profits/thank-you"
              gatedCampaign="manager-toolkit"
              lineOfBusiness={lineOfBusiness.HOSPITALITY}
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image
            className="banner-text"
            imageName="banner-text-management-success-restaurant.png"
          />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial sectionData={drivingRestaurantEfficiency} />
        </Col>

        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="Get a personalized demo and see for yourself"
            mark="."
            subtext="Talk to one of our restaurant technology experts who can set you up with a reporting solution customized to your needs. With better reporting you can make better-informed decisions and grow your bottom line. We’re here to help."
          />
        </Col>
      </Row>

      <Row
        gutter={[32, 32]}
        className="mt-10 lg:mt-20 mb-20 lg:mb-32 px-4 lg:px-0"
      >
        <Col xs={24}>
          <Image
            className="min-w-full"
            imageName="driving-restaurant-efficiency-2.png"
          />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv1;
